import {PropsWithChildren, createContext} from 'react';
import {IncidentSortBy} from '@/status_pages/types';

export interface StatusPageContextProviderProps extends PropsWithChildren {
  componentStatusRank: Record<number, string>;
  componentStatusChoices: any;
  sortEventsBy: IncidentSortBy;
  setSortEventsBy: (sortBy: IncidentSortBy) => void;
}

export const StatusPageContext = createContext({
  componentStatusRank: {},
  componentStatusChoices: {},
  sortEventsBy: IncidentSortBy.MOST_RECENTLY_CREATED,
  setSortEventsBy: null,
});

export const StatusPageContextProvider = ({
  componentStatusRank,
  componentStatusChoices,
  sortEventsBy,
  setSortEventsBy,
  children,
}: StatusPageContextProviderProps) => {
  return (
    <StatusPageContext.Provider
      value={{
        componentStatusRank,
        componentStatusChoices,
        sortEventsBy,
        setSortEventsBy,
      }}
    >
      {children}
    </StatusPageContext.Provider>
  );
};
