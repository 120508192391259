import PastIncidents from '@/status_pages/display/PastIncidents';
import {HistoryTabs} from '@/status_pages/display/components/tabs/History/History';
import {SectionNavContent} from '@/jskit/react/SectionNav';
import {Incident} from '@/status_pages/types';
import {EmptySectionRocket} from '@/status_pages/display/EmptySectionRocket';

export interface PastMaintenanceSectionProps {
  currentSectionId: HistoryTabs;
  incidents: Incident[];
}

export const PastMaintenanceSection = ({incidents, currentSectionId}: PastMaintenanceSectionProps) => (
  <SectionNavContent sectionId={HistoryTabs.PAST_MAINTENANCE} currentSectionId={currentSectionId}>
    {incidents?.length > 0 ? (
      <PastIncidents incidents={incidents} />
    ) : (
      <EmptySectionRocket>
        There is no <strong>Past Maintenance</strong>
      </EmptySectionRocket>
    )}
  </SectionNavContent>
);
