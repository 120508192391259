import {Menu, MenuButton, MenuItem} from '@szhsin/react-menu';
import classnames from 'classnames';
import styles from './IncidentsSortMenu.module.css';
import {IncidentSortBy} from '@/status_pages/types';

export interface IncidentsSortMenuProps {
  setSortBy: (sortBy: IncidentSortBy) => void;
  sortBy?: IncidentSortBy;
}

const menuClassName = ({state}) =>
  state === 'opening' ? styles.menuOpening : state === 'closing' ? styles.menuClosing : styles.menu;

const menuItemClassName = ({hover, disabled}) =>
  disabled ? styles.menuItemDisabled : hover ? styles.menuItemHover : styles.menuItem;

const SortMenuItem = ({
  sortBy,
  setSortBy,
  text,
  subText,
  selected,
}: {
  sortBy: IncidentSortBy;
  setSortBy: (sortBy: IncidentSortBy) => void;
  text: string;
  subText?: React.ReactNode;
  selected: boolean;
}) => {
  return (
    <MenuItem
      className={menuItemClassName}
      onClick={() => {
        setSortBy(sortBy);
      }}
    >
      <div className="w-100 d-flex flex-row justify-content-between align-items-center gap-5 m-0 p-0">
        <div className={classnames({'font-weight-bold': selected})}>{text}</div>
        <button className="btn btn-link text-decoration-none fw-normal">{subText}</button>
      </div>
    </MenuItem>
  );
};

export const IncidentsSortMenu = ({setSortBy, sortBy}: IncidentsSortMenuProps) => {
  return (
    <div className={classnames('ms-2')}>
      <Menu
        menuClassName={menuClassName}
        menuButton={
          <MenuButton className={styles.menuButton}>
            <i className="fa fa-bars-filter mr-2" />
            Sort By
          </MenuButton>
        }
        className="m-0 p-0"
      >
        <SortMenuItem
          sortBy={IncidentSortBy.MOST_RECENTLY_UPDATED}
          setSortBy={setSortBy}
          selected={sortBy === IncidentSortBy.MOST_RECENTLY_UPDATED}
          text="Most Recently Updated"
          subText={
            <>
              Descending <i className="fa fa-arrow-down ml-1" />
            </>
          }
        />
        <SortMenuItem
          sortBy={IncidentSortBy.MOST_RECENTLY_CREATED}
          setSortBy={setSortBy}
          selected={sortBy === IncidentSortBy.MOST_RECENTLY_CREATED}
          text="Most Recently Created"
          subText={
            <>
              Descending <i className="fa fa-arrow-down ml-1" />
            </>
          }
        />
        <SortMenuItem
          sortBy={IncidentSortBy.UPCOMING_FIRST}
          setSortBy={setSortBy}
          selected={sortBy === IncidentSortBy.UPCOMING_FIRST}
          text="Upcoming First"
          subText={
            <>
              Ascending <i className="fa fa-arrow-up ml-1" />
            </>
          }
        />
        <SortMenuItem
          sortBy={IncidentSortBy.UPCOMING_LAST}
          setSortBy={setSortBy}
          selected={sortBy === IncidentSortBy.UPCOMING_LAST}
          text="Upcoming Last"
          subText={
            <>
              Descending <i className="fa fa-arrow-down ml-1" />
            </>
          }
        />
      </Menu>
    </div>
  );
};
