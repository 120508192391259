import PastIncidents from '@/status_pages/display/PastIncidents';
import {HistoryTabs} from '@/status_pages/display/components/tabs/History/History';
import {SectionNavContent} from '@/jskit/react/SectionNav';
import {Incident} from '@/status_pages/types';
import {EmptySectionRocket} from '@/status_pages/display/EmptySectionRocket';

export interface PastIncidentsSectionProps {
  currentSectionId: HistoryTabs;
  incidents: Incident[];
}

export const PastIncidentsSection = ({incidents, currentSectionId}: PastIncidentsSectionProps) => (
  <SectionNavContent sectionId={HistoryTabs.PAST_INCIDENTS} currentSectionId={currentSectionId}>
    {incidents?.length > 0 ? (
      <PastIncidents incidents={incidents} />
    ) : (
      <EmptySectionRocket>
        There are no <strong>Past Incidents</strong>
      </EmptySectionRocket>
    )}
  </SectionNavContent>
);
