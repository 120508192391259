import {Component, Incident, StatusPage, Timestamped, UptimeCalculationType} from '@/status_pages/types';
import {BarEvent, UptimeBarPill} from '@uptime-com/uptimebar';
import {BarEventWithMetadata} from './InspireDateRangeSnakePopup';
import {useStatusPageContext} from '@/status_pages/display/context/hooks';
import moment from 'moment';

interface TimestampedIncident extends Incident, Timestamped {}

/*
 Enrich incidents with unix timestamps, following Timestamped interface
 If start or end is missing - default to startTs or endTs respectively
 */
const generateTimestampedIncidents = (
  statuspage: StatusPage,
  startTs: number,
  endTs: number
): TimestampedIncident[] => {
  return [...statuspage.active_incidents, ...statuspage.past_incidents].map((i) => ({
    ...i,
    start_ts: i.starts_at === null ? startTs : moment(i.starts_at).unix(),
    end_ts: i.ends_at === null ? endTs : moment(i.ends_at).unix(),
  }));
};

/* For given list of events within [startTs..endTs] generate list of unix
   timestamps, representing points of intersections.
   Events:
   [--------******---------**********--------]
   [----*******-----**********---********----]
   Breakpoints:
   [----+---+-+--+--+------+-+---+--+---+----]
 */
const generateBreakpoints = (events: Timestamped[], startTs: number, endTs: number): number[] => {
  const breakpoints: Set<number> = new Set();
  events.forEach((incident) => {
    breakpoints.add(incident.start_ts < startTs ? startTs : incident.start_ts);
    breakpoints.add(incident.end_ts > endTs || !incident.end_ts ? endTs : incident.end_ts);
  });
  return Array.from(breakpoints).sort();
};

/* for given list of affected components and their statuses, return the css
   class representing the one with lowest rank (and highest severity) */

const getLowestRankCssClass = (
  affectedComponents: Record<string, string>,
  componentStatusRank: Record<string, number>
): string => {
  let lowestRank = 6; // operational
  let lowestRankClass = '';
  if (Object.keys(affectedComponents).length == 0) {
    lowestRank = 1;
    lowestRankClass = 'major-outage';
  } else {
    for (const status of Object.values(affectedComponents)) {
      const rank = componentStatusRank[status];
      if (rank < lowestRank) {
        lowestRank = rank;
        lowestRankClass = status;
      }
    }
  }
  return lowestRankClass;
};

/* Generate events suitable for UptimeBar from StatusPage's incidents,
   supplying list of incidents and affected components for each event.
   Affected components are taken from the respective incidents. If multiple
   incidents affecting the same component happen within a certain timeframe,
   highest severity of component is selected.
*/
export const generateEventsFromIncidents = (statuspage: StatusPage, start: Date, end: Date): BarEventWithMetadata[] => {
  const {componentStatusRank} = useStatusPageContext();
  const eventsToReturn: BarEventWithMetadata[] = [];
  const [startTs, endTs] = [moment(start).unix(), moment(end).unix()];
  const timestampedIncidents = generateTimestampedIncidents(statuspage, startTs, endTs);
  const breakpointsSorted = generateBreakpoints(timestampedIncidents, startTs, endTs);
  for (let idx = 0; idx < breakpointsSorted.length - 1; idx++) {
    const midTs = (breakpointsSorted[idx] + breakpointsSorted[idx + 1]) / 2;
    const eligibleIncidents = timestampedIncidents.filter((i) => i.start_ts < midTs && (i.end_ts > midTs || !i.end_ts));
    if (eligibleIncidents.length == 0) {
      continue;
    }

    const affectedComponents: Record<string, string> = {};

    eligibleIncidents.forEach((inc) => {
      inc.affected_components.forEach((afc) => {
        if (
          !affectedComponents[afc.name] ||
          componentStatusRank[affectedComponents[afc.name]] > componentStatusRank[afc.status]
        ) {
          affectedComponents[afc.name] = afc.status;
        }
      });
    });
    const lowestRankCssClass = getLowestRankCssClass(affectedComponents, componentStatusRank);
    const event: BarEvent = {
      start: new Date(Math.max(startTs, breakpointsSorted[idx]) * 1000),
      end: new Date(Math.min(endTs, breakpointsSorted[idx + 1]) * 1000),
      className: `pill-${lowestRankCssClass}`,
    };
    const eventWithIncidentsAndComponents: BarEventWithMetadata = {
      ...event,
      components: affectedComponents,
      incidents: eligibleIncidents,
      eventType: UptimeCalculationType.BY_INCIDENTS,
    };
    eventsToReturn.push(eventWithIncidentsAndComponents);
  }
  return eventsToReturn;
};

/* Generate events suitable for UptimeBar from the checks attached to
   the StatusPages's components. Since we don't have any historical data
   about the component's status in the past during the check downtime, status
   is deducted from the auto-set, and if not set - defaulting to major outage.
 */
export const generateEventsFromCheckDowntimes = (
  statuspage: StatusPage,
  start: Date,
  end: Date
): BarEventWithMetadata[] => {
  const eventsToReturn: BarEventWithMetadata[] = [];
  const [startTs, endTs] = [moment(start).unix(), moment(end).unix()];
  const downtimes = statuspage.downtime_history;
  const breakpointsSorted = generateBreakpoints(downtimes, startTs, endTs);

  const serviceToComponentsMap: Record<number, Component> = {};
  statuspage.components.forEach((cmp) => {
    if (cmp.is_group) {
      cmp.subcomponents.forEach((subcmp) => {
        if (subcmp.service) {
          serviceToComponentsMap[subcmp.service] = subcmp;
        }
      });
    } else {
      if (cmp.service) {
        serviceToComponentsMap[cmp.service] = cmp;
      }
    }
  });

  for (let idx = 0; idx < breakpointsSorted.length - 1; idx++) {
    const midTs = (breakpointsSorted[idx] + breakpointsSorted[idx + 1]) / 2;
    const eligibleDowntimes = downtimes.filter((i) => i.start_ts < midTs && (i.end_ts > midTs || !i.end_ts));
    if (eligibleDowntimes.length == 0) {
      continue;
    }
    const timestampedIncidents = generateTimestampedIncidents(statuspage, startTs, endTs);
    const eligibleIncidents = timestampedIncidents.filter((i) => i.start_ts < midTs && i.end_ts > midTs);
    const event: BarEvent = {
      start: new Date(Math.max(startTs, breakpointsSorted[idx]) * 1000),
      end: new Date(Math.min(endTs, breakpointsSorted[idx + 1]) * 1000),
      className: UptimeBarPill.DANGER,
    };

    const affectedComponents: Record<string, string> = {};
    eligibleDowntimes.forEach((dt) => {
      const component = serviceToComponentsMap[dt.service_id];
      affectedComponents[component.name] = component.auto_status_down || 'major-outage';
    });
    statuspage.components;

    const eventWithIncidentsAndComponents: BarEventWithMetadata = {
      ...event,
      components: affectedComponents,
      incidents: eligibleIncidents,
      eventType: UptimeCalculationType.BY_CHECKS,
    };
    eventsToReturn.push(eventWithIncidentsAndComponents);
  }

  return eventsToReturn;
};
