import React, {useState} from 'react';
import {SectionNav} from '@/jskit/react/SectionNav';
import {ActiveIncidentsSection} from './subtabs/ActiveIncidentsSection';
import {StatusOverviewSection} from './subtabs/StatusOverviewSection';
import {ActiveMaintenanceSection} from './subtabs/ActiveMaintenanceSection';
import {ScheduledMaintenanceSection} from './subtabs/ScheduledMaintenanceSection';
import {ComponentCurrentStatusSection} from './subtabs/ComponentCurrentStatusSection';
import {ComponentStatusValue, Incident, StatusPage, StatusPageTheme} from '@/status_pages/types';
import {CurrentStatusMetrics} from '@/status_pages/display/components/tabs/CurrentStatus/CurrentStatusMetrics';
import {sortBy} from 'underscore';

export enum CurrentStatusTabs {
  STATUS_OVERVIEW,
  ACTIVE_INCIDENTS,
  ACTIVE_MAINTENANCE,
  SCHEDULED_MAINTENANCE,
  COMPONENT_CURRENT_STATUS,
}

export interface CurrentStatusProps {
  statuspage: StatusPage;
  componentStatusRank: Record<ComponentStatusValue, number>;
  componentStatusChoices: Record<ComponentStatusValue, string>;
  metricDatapointsURL: string;
  hasLoaded: boolean;
  historyStartDate: object;
  historyEndDate: object;
}

export const CurrentStatus = ({
  statuspage,
  componentStatusRank,
  componentStatusChoices,
  metricDatapointsURL,
  historyStartDate,
  historyEndDate,
  hasLoaded,
}: CurrentStatusProps) => {
  const [currentTab, setCurrentTab] = useState(CurrentStatusTabs.STATUS_OVERVIEW);
  const allTabs = [[CurrentStatusTabs.STATUS_OVERVIEW, 'Status Overview']];
  const allIncidents: Incident[] = statuspage.active_incidents || [];
  const activeIncidents = allIncidents.filter((incident) => incident.incident_type === 'INCIDENT');
  const activeMaintenance = allIncidents.filter(
    (incident) => incident.incident_type === 'SCHEDULED_MAINTENANCE' && incident.starts_at <= new Date().toISOString()
  );
  const upcomingMaintenance = allIncidents.filter(
    (incident) => incident.incident_type === 'SCHEDULED_MAINTENANCE' && incident.starts_at > new Date().toISOString()
  );
  const pastIncidents: Incident[] = (statuspage.past_incidents || []).filter(
    (incident) => incident.incident_type === 'INCIDENT'
  );
  const hasNothingButComponents =
    statuspage.metrics.length == 0 &&
    statuspage.active_incidents.length == 0 &&
    statuspage.upcoming_maintenance.length == 0;

  if (statuspage.show_active_incidents) {
    if (!statuspage.hide_empty_tabs_status || activeIncidents.length > 0) {
      allTabs.push([CurrentStatusTabs.ACTIVE_INCIDENTS, 'Active Incidents']);
    }
    if (!statuspage.hide_empty_tabs_status || activeMaintenance.length > 0) {
      allTabs.push([CurrentStatusTabs.ACTIVE_MAINTENANCE, 'Active Maintenance']);
    }
    if (!statuspage.hide_empty_tabs_status || upcomingMaintenance.length > 0) {
      allTabs.push([CurrentStatusTabs.SCHEDULED_MAINTENANCE, 'Scheduled Maintenance']);
    }
  }
  if (!hasNothingButComponents && (!statuspage.hide_empty_tabs_status || statuspage.components.length > 0)) {
    allTabs.push([CurrentStatusTabs.COMPONENT_CURRENT_STATUS, 'Component Current Status']);
  }
  const lastIncident = sortBy(activeIncidents.concat(pastIncidents), (incident) => incident.starts_at).at(-1) || null;
  return (
    <>
      {hasLoaded && statuspage.theme === StatusPageTheme.INSPIRE && (
        <CurrentStatusMetrics
          statuspage={statuspage}
          historyStartDate={historyStartDate}
          historyEndDate={historyEndDate}
          lastIncident={lastIncident}
        />
      )}
      <SectionNav
        sectionIds={allTabs}
        currentSectionId={currentTab}
        navClass="nav nav-tabs-plain nav-tabs-secondary mb-4"
        onSectionNav={setCurrentTab}
      >
        <StatusOverviewSection
          currentSectionId={currentTab}
          statuspage={statuspage}
          componentStatusRank={componentStatusRank}
          componentStatusChoices={componentStatusChoices}
          metricDatapointsURL={metricDatapointsURL}
        />
        <ActiveIncidentsSection currentSectionId={currentTab} incidents={activeIncidents} />
        <ActiveMaintenanceSection currentSectionId={currentTab} incidents={activeMaintenance} />
        <ScheduledMaintenanceSection currentSectionId={currentTab} incidents={upcomingMaintenance} />
        <ComponentCurrentStatusSection
          currentSectionId={currentTab}
          statuspage={statuspage}
          componentStatusRank={componentStatusRank}
          componentStatusChoices={componentStatusChoices}
        />
      </SectionNav>
    </>
  );
};
