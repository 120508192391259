'use strict';

import _ from 'underscore';
import React from 'react';
import ReactUtils from '../../jskit/react/ReactUtils';
import Schedule from './../common/Schedule';
import CheckBox from '@/jskit/react/forms/CheckBox';

export default class ServiceMaintenance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      scheduleFormData: {},
    };
  }
  serialize() {
    return this.refs.schedule.serialize();
  }

  deserialize(schedule) {
    this.refs.schedule.deserialize(schedule);
  }

  render() {
    const setScheduleFormData = (data) => {
      this.setState({scheduleFormData: data});
    };
    const scheduleState = this.state.scheduleFormData.schedule_state;
    const hasPauseOnMaintenanceFF = this.props.featureFlags.includes('pause_on_maintenance');
    const maintenanceWindowDesc = this.props.choices.maintenance_window_descriptions || {};
    const availableStates = _.object(
      Object.entries(maintenanceWindowDesc).map(([k, [t1, t2, icon]]) => [
        k,
        <span className="d-inline-block mb-1">
          {t1}
          <i className={ReactUtils.cssClass('text-muted ml-2', icon)} />
          <br />
          <small className="text-muted">{t2}</small>
        </span>,
      ])
    );
    const {formLink} = this.props;
    return (
      <div className="mb-3">
        <div className="mb-4">
          <i className="fas fa-info-circle"></i>&nbsp; During maintenance any failed checks will be ignored in uptime
          calculations and alerts will not be sent.
        </div>
        <Schedule
          ref="schedule"
          availableStates={availableStates}
          choices={this.props.choices}
          errors={this.props.errors}
          onChange={setScheduleFormData}
        />
        {hasPauseOnMaintenanceFF &&
          (scheduleState === 'SCHEDULED' ||
            (scheduleState === 'SUPPRESSED' && !this.props.isUnderSuppressedMaintenance)) && (
            <CheckBox
              fieldName="pause_on_scheduled_maintenance"
              formLink={formLink}
              labelText="Stop check execution during maintenance mode"
              titleText="When enabled, checks are paused and not executed during maintenance mode. Default behavior under maintenance mode is the check continues to execute but alerts and notifications are suppressed."
            />
          )}
      </div>
    );
  }
}
