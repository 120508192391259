'use strict';

import React from 'react';
import moment from 'moment-timezone';
import _ from 'underscore';
import Utils from '../../jskit/general/Utils';
import Ajax from '../../jskit/general/Ajax';
import URLHistory from '../../jskit/general/URLHistory';
import ReactUtils from '../../jskit/react/ReactUtils';
import AutoRefresh from '../../jskit/react/AutoRefresh';
import {SectionNav, SectionNavContent} from '../../jskit/react/SectionNav';
import {postAlertMessage, hideAlertMessages} from '../../js/global/alerts';
import {LegacyGlobalStatus} from '@/status_pages/display/components/theme-specific/legacy/LegacyGlobalStatus.tsx';
import * as ManageUtils from '../manage/ManageUtils.jsx';
import SubscriptionManageForm from './SubscriptionManageForm.jsx';
import MessageBox from '../../js/global/messagebox';

import 'reactjs-popup/dist/index.css';
import './StatusPageDisplayController.css';
import {CurrentStatus} from '@/status_pages/display/components/tabs/CurrentStatus/CurrentStatus.tsx';
import {History} from '@/status_pages/display/components/tabs/History/History';
import {StatusPageHeader} from '@/status_pages/display/components/StatusPageHeader';
import {Toolbar} from '@/status_pages/display/components/Toolbar';
import {SubscribeButton} from '@/status_pages/display/components/SubscribeButton';
import {ThemeSwitchButton} from '@/status_pages/display/components/theme-specific/inspire/ThemeSwitchButton';
import {StatusPageFooter} from '@/status_pages/display/components/StatusPageFooter';
import {HistoryHeader} from '@/status_pages/display/components/HistoryHeader';
import Cookie from '@/jskit/general/Cookie';
import {IncidentSortBy, StatusPageTheme} from '@/status_pages/types';
import {StatusPageContextProvider} from '@/status_pages/display/context/StatusPageContextProvider';

const Tabs = {
  TAB_STATUS: 'tab-status',
  TAB_HISTORY: 'tab-history',
};

const PageMode = {
  STATUS_PAGE: 'status-page',
  SUBSCRIPTION: 'subscription',
};

const OAUTH_TYPES = ['SLACK'];

const INSPIRE_THEME_COOKIE = 'uptime_sp_theme_settings';
const SORT_EVENTS_COOKIE = 'uptime_sp_sort_events';

const SubscriptionCreateSuccessMsg = (name) =>
  `You are now subscribed to receive <strong>${_.escape(name)}</strong> notifications!`;
const SubscriptionUpdateSuccessMsg = 'Your subscription has been updated.';
const SubscriptionUnsubscribeSuccessMsg = (name) =>
  `You have successfully unsusbcribed from <strong>${_.escape(name)}</strong> updates.`;

export default class StatusPageDisplayController extends React.Component {
  constructor(props) {
    super(props);
    Utils.autoBindClass(this);
    this.ALL_TABS = [];
    // in order to show Current Status tab, it has to be:
    // 1) enabled in general
    // 2) there should be enough information to show if
    //    the option to hide empty tabs is selected
    if (
      this.props.statuspage.show_status_tab &&
      (!this.props.statuspage.hide_empty_tabs_status || this.hasEnoughInfoForCurrentStatusTab())
    ) {
      this.ALL_TABS.push([
        Tabs.TAB_STATUS,
        <>
          <i className="tab-icon fa-solid fa-newspaper mr-2 d-none"></i>Current Status
        </>,
      ]);
    }
    if (this.props.statuspage.show_history_tab) {
      this.ALL_TABS.push([
        Tabs.TAB_HISTORY,
        <>
          <i className="tab-icon fa-solid fa-clock-rotate-left mr-2 d-none"></i>History
        </>,
      ]);
    }

    const mode = this.props.subscriptionTarget ? PageMode.SUBSCRIPTION : PageMode.STATUS_PAGE;
    this.state = {
      currentTab: this.ALL_TABS.length ? this.ALL_TABS[0][0] : null,
      historyStartDate: null,
      historyEndDate: null,
      hasLoaded: false,
      statuspage: this.props.statuspage,
      subscribeFormValidationErrors: null,
      mode: mode,
      subscriptionTarget: this.props.subscriptionTarget,
      subscriptionTargetExtras: this.props.subscriptionTargetExtras,
      subscriptionType: this.props.subscriptionType,
      subscriptionAction: this.props.subscriptionAction,
      subscriptionSignature: this.props.subscriptionSignature,
      subscriptionPopUpOpen: false,
      sortEventsBy: IncidentSortBy.MOST_RECENTLY_CREATED,
    };
  }

  componentDidMount() {
    const sp = this.props.statuspage;
    if (sp.theme === StatusPageTheme.INSPIRE) {
      const themeSettings = Cookie.getJSONCookie(INSPIRE_THEME_COOKIE) || {};
      if (themeSettings[sp.id] !== undefined) {
        this.setTheme(themeSettings[sp.id]);
      }
    }
    // set default sorting from cookie
    const sortSettings = Cookie.getJSONCookie(SORT_EVENTS_COOKIE) || {
      [sp.id]: IncidentSortBy.MOST_RECENTLY_CREATED,
    };
    if (sp.show_history_tab) {
      URLHistory.addStateChangeListener(() => {
        this.loadDateRangeFromURL();
      });
    }

    this.setState({sortEventsBy: sortSettings[sp.id]}, () => this.loadDateRangeFromURL());

    if (sp.allow_subscriptions) {
      const displaySubscribePopUp = URLHistory.queryStringToObject().subscribe;
      const subscriptionAction = this.state.subscriptionAction;
      if (subscriptionAction === 'initial') {
        postAlertMessage(`You are now subscribed to get ${sp.name} updates!`, 'success', true);
      }
      if (subscriptionAction === 'verify-success') {
        postAlertMessage(`You are now subscribed to get ${sp.name} updates!`, 'success', true);
        URLHistory.replaceState(
          URLHistory.updateQueryString(null, null, [
            'subscriptionTarget',
            'subscriptionType',
            'subscriptionAction',
            'sig',
          ])
        );
      }
      if (subscriptionAction === 'verify-failure') {
        postAlertMessage('There was an error. Try subscribing again.', 'danger', true);
        URLHistory.replaceState(
          URLHistory.updateQueryString(null, null, [
            'subscriptionTarget',
            'subscriptionType',
            'subscriptionAction',
            'sig',
          ])
        );
      }
      if (displaySubscribePopUp !== undefined) {
        URLHistory.replaceState(URLHistory.updateQueryString(null, null, ['subscribe']));
        setTimeout(() => this.setState({subscriptionPopUpOpen: true}), 500);
      }
    }
  }

  loadDateRangeFromURL(skipTabChange) {
    const queryString = URLHistory.queryStringToObject();
    this.loadAllData(queryString.start, queryString.end, {initial: true});

    if ((queryString.start || queryString.end) && !skipTabChange) {
      this.setState({currentTab: Tabs.TAB_HISTORY});
    }
  }

  handleSetSortEventsBy = (sortEventsBy) => {
    const sortSettings = Cookie.getJSONCookie(SORT_EVENTS_COOKIE) || {};
    sortSettings[this.state.statuspage.id] = sortEventsBy;
    Cookie.setJSONCookie(SORT_EVENTS_COOKIE, sortSettings, 90);
    this.setState({sortEventsBy}, () => {
      this.loadAllData(this.state.historyStartDate, this.state.historyEndDate, {initial: true});
    });
  };

  handleRefresh() {
    // only perform refresh if on status tab; otherwise just skip the update
    if (this.state.currentTab !== Tabs.TAB_STATUS) {
      return;
    }
    new Ajax().get({
      url: this.props.updateStatusPageURL,
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        const statuspage = Object.assign({}, this.state.statuspage);
        Object.assign(statuspage, data.data);
        this.setState(
          {
            statuspage,
          },
          () => this.loadDateRangeFromURL(true)
        );
      }.bind(this),
    });
  }

  updateDateRange(start, end, options) {
    const startFmt = start.format('YYYYMMDD');
    const endFmt = end.format('YYYYMMDD');
    options = options || {};

    if (!options.initial) {
      if (options.pushState) {
        URLHistory.pushState({start: startFmt, end: endFmt});
      } else {
        URLHistory.replaceState({start: startFmt, end: endFmt});
      }
    }

    this.setState({
      historyStartDate: moment(start),
      historyEndDate: moment(end),
      historyStartFmt: startFmt,
      historyEndFmt: endFmt,
      hasLoaded: true,
    });
  }

  loadAllData(start, end, updateOptions) {
    // start and end are already tz-aware in browser's timezone
    // if start is empty - set it to current now in SPs timezone
    const sp = this.state.statuspage;
    const now = moment().tz(sp.used_timezone).endOf('day');

    if (start) {
      start = moment(start).startOf('day');
    } else {
      start = moment()
        .subtract(Math.max(sp.default_history_date_range - 1, 0), 'days')
        .startOf('day');
    }

    if (end) {
      end = moment(end).endOf('day');
      if (end > now) {
        end = now;
      }
    } else {
      end = now;
    }

    const query = URLHistory.objectToQueryString({
      start: start.format('YYYYMMDD'),
      end: end.format('YYYYMMDD'),
      sort: this.state.sortEventsBy,
    });
    this.setState({hasLoaded: false});
    new Ajax().get({
      url: this.props.updateHistoryURL + '?' + query,
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        const statuspage = Object.assign(Object.assign({}, this.state.statuspage), data.data);
        Object.entries(data.data.component_history).forEach(([cmpId, cmpHistory]) => {
          const cmp = ManageUtils.findComponentIndex(statuspage.components, Number(cmpId), false);
          if (cmp) {
            Object.assign(cmp.component.history, cmpHistory);
          }
        });
        const periodStart = moment.unix(data.data.period_start).clone().tz(sp.used_timezone);
        const periodEnd = moment.unix(data.data.period_end).clone().tz(sp.used_timezone);
        this.setState(
          {
            statuspage,
          },
          () => this.updateDateRange(periodStart, periodEnd, updateOptions)
        );
      }.bind(this),
    });
  }

  handleTabChange(tab) {
    this.setState({currentTab: tab});
  }

  clearValidationErrors() {
    this.setState({subscribeFormValidationErrors: null});
  }

  createOrUpdateSubscription(payload, doUnsubscribe) {
    this.clearValidationErrors();
    const {subscribeURL} = this.props;
    new Ajax().post({
      url: subscribeURL,
      data: payload,
      encoder: 'json',
      decoder: 'json',
      success: function (data) {
        if (data.success) {
          hideAlertMessages();
          const oauthUnsubscribe = OAUTH_TYPES.includes(data.data.subscriptionType) && doUnsubscribe;
          if (data.data.componentSelection && !oauthUnsubscribe) {
            // backend tells we need page with component selection
            this.setState(
              {
                mode: PageMode.SUBSCRIPTION,
                subscriptionTarget: data.data.subscriptionTarget,
                subscriptionTargetExtras: data.data.subscriptionTargetExtras,
                subscriptionType: data.data.subscriptionType,
                subscriptionSignature: data.data.subscriptionSignature,
                subscriptionAction: data.data.subscriptionAction,
              },
              () => URLHistory.pushState(URLHistory.updateQueryString(null, data.data.subscriptionQuery, []))
            );
            if (data.data.updateComplete) {
              // we're staying on component selection page, but update is complete (e.g. when
              // customer deselected all components. In this case, we're staying on the component
              // selection page, customer can continue selecting/saving components.
              postAlertMessage(SubscriptionUpdateSuccessMsg, 'success', true);
            }
          } else {
            // no next page is required, redirect to main SP page and provide success message
            const successMessage = `${SubscriptionCreateSuccessMsg(this.props.statuspage.name)}`;
            const updateMessage = this.state.subscriptionSignature ? SubscriptionUpdateSuccessMsg : successMessage;
            const unsubscribeMessage = SubscriptionUnsubscribeSuccessMsg(this.props.statuspage.name);
            const hasComponents = payload.components && payload.components.length;
            if (this.state.subscriptionAction === 'unsubscribe' || hasComponents === 0) {
              postAlertMessage(unsubscribeMessage, 'success', true);
            } else {
              postAlertMessage(updateMessage, 'success', true);
            }
            URLHistory.replaceState(
              URLHistory.updateQueryString(null, null, [
                'subscriptionTarget',
                'subscriptionType',
                'subscriptionAction',
                'sig',
              ])
            );
            this.setState({
              mode: PageMode.STATUS_PAGE,
              subscriptionAction: null,
              subscriptionTarget: null,
              subscriptionSignature: null,
            });
          }
        } else {
          if (this.state.mode === PageMode.STATUS_PAGE) {
            this.setState({subscribeFormValidationErrors: data.fields.__all__});
          } else if (this.state.mode === PageMode.SUBSCRIPTION) {
            postAlertMessage(data.fields.__all__, 'error', true);
          }
        }
      }.bind(this),
    });
  }

  handleUpdateSubscription(componentIds, doUnsubscribe) {
    const payload = {
      components: componentIds,
      subscription_target: this.state.subscriptionTarget,
      subscription_type: this.state.subscriptionType,
      subscription_signature: this.state.subscriptionSignature,
      subscription_action: this.state.subscriptionAction,
      agree_to_terms: true,
    };
    this.createOrUpdateSubscription(payload, doUnsubscribe);
  }

  handleCancelUpdateSubscription() {
    this.setState({mode: PageMode.STATUS_PAGE, subscriptionTarget: null});
  }

  handleHistoryDateRangeChange(start, end) {
    this.loadAllData(start, end);
  }

  handleDateRangeSnakeClick(date, e) {
    e.preventDefault();
    this.setState({snakePopupDate: null});
    this.loadAllData(date, moment(date).endOf('day'), {pushState: true});
  }

  handleUnsubscribe() {
    const forcedUnsubscribe = this.state.subscriptionAction === 'unsubscribe';

    const confirmFn = () => {
      MessageBox.confirmBox(
        `Are you sure you want to unsubscribe from ${this.props.statuspage.name} notifications?`,
        'Unsubscribe',
        function () {
          this.handleUpdateSubscription([], true);
        }.bind(this)
      );
    };

    if (forcedUnsubscribe) {
      this.setState({mode: PageMode.STATUS_PAGE}, confirmFn);
    } else {
      confirmFn();
    }
  }

  hasEnoughInfoForCurrentStatusTab() {
    const sp = this.props.statuspage;
    const hasIncidentsToDisplay =
      sp.show_active_incidents && (sp.active_incidents.length > 0 || sp.upcoming_maintenance.length > 0);
    const hasMetricsToDisplay = sp.metrics?.length > 0;
    const hasComponents = sp.components.length > 0;
    return hasIncidentsToDisplay || hasMetricsToDisplay || hasComponents;
  }

  render() {
    const sp = this.state.statuspage;
    if (this.state.mode === PageMode.SUBSCRIPTION) {
      return (
        <SubscriptionManageForm
          onSave={this.handleUpdateSubscription}
          onCancel={this.handleCancelUpdateSubscription}
          initialSubscribe={!this.state.subscriptionSignature}
          subscriptionType={this.state.subscriptionType}
          forceUnsubscribe={this.state.subscriptionAction === 'unsubscribe'}
          onUnsubscribe={this.handleUnsubscribe}
          subscriptionComponents={this.props.subscriptionComponents}
          statuspage={sp}
          subscriptionTarget={this.state.subscriptionTarget}
          subscriptionTargetExtras={this.state.subscriptionTargetExtras}
          errors={this.state.subscribeFormValidationErrors}
        />
      );
    }
    const toolBarIcons = this.getToolbarIcons(sp.theme);
    const isLegacyTheme = sp.theme === StatusPageTheme.LEGACY;
    const hasTabs = this.ALL_TABS.length >= 2;
    const hasCustomHeader = sp.custom_header_html && sp.custom_header_html.length;
    const ToolBar = (
      <Toolbar
        className={ReactUtils.cssClass({
          'status-buttons': isLegacyTheme,
          'mb-3': isLegacyTheme && !hasTabs,
        })}
        containerClassName={ReactUtils.cssClass({'pull-down': isLegacyTheme && hasTabs && hasCustomHeader})}
      >
        {toolBarIcons}
      </Toolbar>
    );
    return (
      // TODO: Use context in all other places to remove those omnipresent props propagation
      <StatusPageContextProvider
        componentStatusChoices={this.props.componentStatusChoices}
        componentStatusRank={this.props.componentStatusRank}
        setSortEventsBy={this.handleSetSortEventsBy}
        sortEventsBy={this.state.sortEventsBy}
      >
        <StatusPageHeader statuspage={sp} historyStartDate={this.state.historyStartDate} toolbar={ToolBar} />
        <div
          className={ReactUtils.cssClass({
            'white-block white-block-border p-4': sp.theme === StatusPageTheme.LEGACY,
            'pl-lg-6 pr-lg-6 pt-4 TabsContainer': sp.theme === StatusPageTheme.INSPIRE,
          })}
        >
          {isLegacyTheme && hasCustomHeader && ToolBar}
          {this.renderSections()}
        </div>
        <StatusPageFooter theme={sp.theme} brandName={this.props.brandName} />
      </StatusPageContextProvider>
    );
  }

  renderSections() {
    // if there's just one section - don't render the tab bar
    const sp = this.state.statuspage;

    if (!this.ALL_TABS.length) {
      return null;
    }

    const sections = (
      <>
        {this.renderCurrentStatusTab(sp)}
        {this.renderHistoryIncidentsTab(sp)}
      </>
    );
    if (this.ALL_TABS.length > 1) {
      return (
        <SectionNav
          sectionIds={this.ALL_TABS}
          currentSectionId={this.state.currentTab}
          navClass="nav nav-tabs-plain nav-tabs-primary mb-4"
          onSectionNav={this.handleTabChange}
          gridClass="col-lg-12 NavTabsPrimaryContainer"
          contentClass={sp.theme === StatusPageTheme.INSPIRE ? 'white-block p-3 p-lg-5' : ''}
        >
          {sections}
        </SectionNav>
      );
    }
    return (
      <div
        className={ReactUtils.cssClass({'white-block white-block-border p-5': sp.theme === StatusPageTheme.INSPIRE})}
      >
        {sections}
      </div>
    );
  }

  getToolbarIconsLegacy() {
    return [
      <AutoRefresh ref="autoRefresh" refreshFunction={this.handleRefresh} extraClass="mr-2 mb-1" />,
      <button
        type="button"
        title="Toggle Full Screen"
        onClick={Utils.toggleFullScreenMode}
        className="btn btn-white mr-2 mb-1 d-none d-sm-inline-block"
      >
        <i className="fas fa-fw fa-expand-arrows-alt" />
      </button>,
      this.props.statuspage.allow_subscriptions && (
        <SubscribeButton
          statuspage={this.props.statuspage}
          isOpen={this.state.subscriptionPopUpOpen}
          errors={this.state.subscribeFormValidationErrors}
          onCreateOrUpdateSubscription={this.createOrUpdateSubscription}
          onClearValidationErrors={this.clearValidationErrors}
          slackOAuthInitUrl={this.props.slackOAuthInitURL}
          rssUrl={this.props.rssURL}
          termsConditionsUrl={this.props.termsConditionsURL}
          privacyPolicyUrl={this.props.privacyPolicyURL}
          popupTrigger={
            <button className="btn btn-primary mb-1">
              <i className="fas fa-rss"></i> Subscribe to updates
            </button>
          }
        />
      ),
    ];
  }

  getToolbarIconsInspire() {
    return [
      <ThemeSwitchButton onClick={this.handleSwitchTheme} />,
      <AutoRefresh
        ref="autoRefresh"
        extraClass="d-none d-lg-inline-block"
        extraBtnClass="btn btn-link pl-0 pr-0"
        refreshFunction={this.handleRefresh}
      />,
      <button
        type="button"
        title="Toggle Full Screen"
        onClick={Utils.toggleFullScreenMode}
        className="btn btn-link d-none d-lg-inline-block"
      >
        <i className="fas fa-fw fa-expand-arrows-alt" />
      </button>,
      this.props.statuspage.allow_subscriptions && (
        <SubscribeButton
          statuspage={this.props.statuspage}
          isOpen={this.state.subscriptionPopUpOpen}
          errors={this.state.subscribeFormValidationErrors}
          onCreateOrUpdateSubscription={this.createOrUpdateSubscription}
          onClearValidationErrors={this.clearValidationErrors}
          slackOAuthInitUrl={this.props.slackOAuthInitURL}
          rssUrl={this.props.rssURL}
          termsConditionsUrl={this.props.termsConditionsURL}
          privacyPolicyUrl={this.props.privacyPolicyURL}
          popupTrigger={<button className="btn btn-link mb-1 SubscribeButton">Subscribe to Updates</button>}
        />
      ),
    ];
  }

  getToolbarIcons(theme) {
    if (theme === StatusPageTheme.INSPIRE) {
      return this.getToolbarIconsInspire();
    } else {
      return this.getToolbarIconsLegacy();
    }
  }

  setTheme(themeClass) {
    const allowedThemeVersions = ['light', 'dark'];
    if (!allowedThemeVersions.includes(themeClass)) {
      return;
    }
    const container = document.body;
    const themeSettings = Cookie.getJSONCookie(INSPIRE_THEME_COOKIE) || {};
    container.classList.remove('light', 'dark');
    container.classList.add(themeClass);
    themeSettings[this.props.statuspage.id] = themeClass;
    Cookie.setJSONCookie(INSPIRE_THEME_COOKIE, themeSettings, 90);
  }

  handleSwitchTheme() {
    const container = document.body;
    if (container.classList.contains('dark')) {
      this.setTheme('light');
    } else {
      this.setTheme('dark');
    }
  }

  renderCurrentStatusTab(sp) {
    if (!sp.show_status_tab) {
      return null;
    }
    if (this.ALL_TABS.length === 0 || this.ALL_TABS[0][0] !== Tabs.TAB_STATUS) {
      return null;
    }
    return (
      <SectionNavContent sectionId={Tabs.TAB_STATUS} currentSectionId={this.state.currentTab}>
        {sp.theme === StatusPageTheme.LEGACY && <LegacyGlobalStatus statuspage={sp} />}
        <CurrentStatus
          statuspage={sp}
          componentStatusRank={this.props.componentStatusRank}
          componentStatusChoices={this.props.componentStatusChoices}
          metricDatapointsURL={this.props.metricDatapointsURL}
          hasLoaded={this.state.hasLoaded}
          historyStartDate={this.state.historyStartDate}
          historyEndDate={this.state.historyEndDate}
        />
      </SectionNavContent>
    );
  }

  renderHistoryIncidentsTab(sp) {
    if (!sp.show_history_tab) {
      return null;
    }
    return (
      <SectionNavContent sectionId={Tabs.TAB_HISTORY} currentSectionId={this.state.currentTab}>
        {sp.theme === StatusPageTheme.LEGACY && sp.hide_empty_tabs_status && this.ALL_TABS.length < 2 && (
          <LegacyGlobalStatus statuspage={sp} />
        )}
        <HistoryHeader
          statuspage={this.state.statuspage}
          historyStartDate={this.state.historyStartDate}
          historyEndDate={this.state.historyEndDate}
          historyStartFmt={this.state.historyStartFmt}
          historyEndFmt={this.state.historyEndFmt}
          handleHistoryDateRangeChange={this.handleHistoryDateRangeChange}
          hasLoaded={this.state.hasLoaded}
          pdfUrl={this.props.pdfURL}
          componentStatusRank={this.props.componentStatusRank}
          handleDateRangeSnakeClick={this.handleDateRangeSnakeClick}
        />
        <History
          statuspage={sp}
          componentStatusRank={this.props.componentStatusRank}
          componentStatusChoices={this.props.componentStatusChoices}
          historyStartDate={this.state.historyStartDate}
          historyEndDate={this.state.historyEndDate}
        />
      </SectionNavContent>
    );
  }
}
