import React from 'react';
import Formatter from '../../jskit/general/Formatter';
import Incident from './Incident.jsx';
import {IncidentsSortMenu} from '@/status_pages/display/components/IncidentsSortMenu';
import ReactUtils from '@/jskit/react/ReactUtils.jsx';
import {Incident as IncidentType, IncidentSortBy} from '@/status_pages/types';
import {useStatusPageContext} from '@/status_pages/display/context/hooks';

export interface PastIncidentsProps {
  incidents: IncidentType[];
  title?: string;
}

export const PastIncidents = ({incidents, title}: PastIncidentsProps) => {
  const {sortEventsBy, setSortEventsBy, componentStatusRank} = useStatusPageContext();
  const pastIncidentsPerMonth: Record<string, {month: string; monthDisplay: string; incidents: IncidentType[]}> = {};
  incidents.forEach((incident) => {
    const date = Formatter.localizeDate(incident.starts_at);
    const month = date.format('YYYYMM');

    if (!pastIncidentsPerMonth[month]) {
      pastIncidentsPerMonth[month] = {
        month: month,
        monthDisplay: date.format('MMMM YYYY'),
        incidents: [],
      };
    }
    pastIncidentsPerMonth[month].incidents.push(incident);
  });
  const reverseMonthSort = sortEventsBy === IncidentSortBy.UPCOMING_FIRST ? -1 : 1;
  const months = Object.values(pastIncidentsPerMonth).sort((a, b) =>
    a.month < b.month ? reverseMonthSort : -1 * reverseMonthSort
  );

  const renderedMonths = months.map((month) => {
    return (
      <div key={month.month} className="mb-4">
        <div className="font-14 mb-2">
          {month.monthDisplay} ({month.incidents.length})
        </div>
        <div className="incident-list past-incidents">
          {month.incidents.map((incident, j) => (
            <Incident key={j} incident={incident} componentStatusRank={componentStatusRank} />
          ))}
          <div className="incident-end"></div>
        </div>
      </div>
    );
  });

  return (
    <>
      <div
        className={ReactUtils.cssClass('d-flex flex-row justify-content-between align-items-center', {
          'mb-4': title,
        })}
      >
        <h4 className="m-0 p-0">{title}</h4>
        <IncidentsSortMenu setSortBy={setSortEventsBy} sortBy={sortEventsBy} />
      </div>
      {renderedMonths}
    </>
  );
};

export default PastIncidents;
