export enum StatusPageTheme {
  LEGACY = 'LEGACY',
  INSPIRE = 'INSPIRE',
}

export enum StatusPageGlobalStatus {
  OPERATIONAL = 'OPERATIONAL',
  ISSUES = 'ISSUES',
  MAINTENANCE = 'MAINTENANCE',
}

export enum StatusPageVisibilityLevel {
  UPTIME_USERS = 'UPTIME_USERS',
  EXTERNAL_USERS = 'EXTERNAL_USERS',
  PUBLIC = 'PUBLIC',
}

export enum UptimeCalculationType {
  BY_INCIDENTS = 'BY_INCIDENTS',
  BY_CHECKS = 'BY_CHECKS',
}

export enum PerformanceDataType {
  NONE = 'NONE',
  RESPONSE_TIME = 'RESPONSE_TIME',
  JITTER_DELTA = 'JITTER_DELTA',
  DAYS_TO_EXPIRY = 'DAYS_TO_EXPIRY',
  RUM_LOAD_TIME = 'RUM_LOAD_TIME',
  CUSTOM = 'CUSTOM',
}

export interface StatusPageGlobalMetrics {
  downtime_secs?: number | null;
  outages?: number | null;
  uptime_pct?: number | null;
}

export interface SAMLSettings {
  sp_uuid: string;
  idp_entity_id: string;
  idp_sso_target_url: string;
  idp_certificate: string;
  sp_entity_id: string;
  sp_acs_url: string;
  sp_wayfless_url: string;
  use_sha1_for_signature: boolean;
  unsigned_authn_requests: boolean;
}

export enum ComponentStatusValue {
  OPERATIONAL = 'operational',
  MAJOR_OUTAGE = 'major-outage',
  PARTIAL_OUTAGE = 'partial-outage',
  DEGRADED_PERFORMANCE = 'degraded-performance',
  NOTIFICATION = 'notification',
  UNDER_MAINTENANCE = 'under-maintenance',
  NO_CHANGE = '',
}

export enum IncidentSortBy {
  MOST_RECENTLY_UPDATED = 'MOST_RECENTLY_UPDATED',
  MOST_RECENTLY_CREATED = 'MOST_RECENTLY_CREATED',
  UPCOMING_FIRST = 'UPCOMING_FIRST',
  UPCOMING_LAST = 'UPCOMING_LAST',
}

export interface ComponentStatus {
  value: ComponentStatusValue;
  rank: number;
  display: string;
  color: string;
  is_operational: boolean;
  is_failure: boolean;
}

export interface Metric {
  id: number;
  name: string;
  source: string;
  service: number;
  service_name: string;
  service_type: string;
  is_visible: boolean;
}

export interface Component {
  id: number;
  name: string;
  description: string;
  group?: number;
  is_group: boolean;
  performance_data_type: PerformanceDataType;
  status: string;
  auto_status_down: string;
  auto_status_up: string;
  service: number;
  service_name: string;
  service_type: string;
  service_address: string;
  service_uptime_sla: number;
  service_response_time_sla: number;
  sorting_weight: number;
  just_added: boolean;
  cached_response_time: number;
  subcomponents: Component[];
}

export interface Metric {
  id: number;
  name: string;
  source: string;
  service: number;
  service_name: string;
  service_type: string;
  is_visible: boolean;
}

export enum IncidentType {
  INCIDENT = 'INCIDENT',
  SCHEDULED_MAINTENANCE = 'SCHEDULED_MAINTENANCE',
}

interface AffectedComponentComponent {
  id: number;
  name: string;
}

export interface AffectedComponent {
  id: number;
  obj_id?: number;
  name: string;
  status: string;
  status_display: string;
  description: string;
  component: AffectedComponentComponent;
}

export enum IncidentState {
  INVESTIGATION = 'investigating',
  IDENTIFIED = 'identified',
  MONITORING = 'monitoring',
  RESOLVED = 'resolved',
  NOTIFICATION = 'notification',
  MAINTENANCE = 'maintenance',
}

export interface IncidentUpdate {
  id: number;
  updated_at?: string;
  description: string;
  is_modified: boolean;
  description_html: string;
  incident_state: IncidentState;
  incident_state_display: string;
}

export interface Incident {
  id: number;
  name: string;
  duration: number;
  starts_at: string;
  ends_at: string;
  updates: IncidentUpdate[];
  incident_type: IncidentType;
  include_in_global_metrics: boolean;
  update_component_status: boolean;
  notify_subscribers: boolean;
  resolve_components: boolean;
  affected_components: AffectedComponent[];
}

export interface Timestamped {
  start_ts: number;
  end_ts: number;
}

export interface StatusPageDowntimeItem extends Timestamped {
  service_id: number;
}

export interface StatusPage {
  id: number;
  name: string;
  description_html: string;
  page_type: string;
  private_url: string;
  public_url: string;
  cname_url: string;
  subscribers_count: string;
  slug: string;
  cname: string;
  description: string;
  default_history_date_range: number;
  max_visible_days: number;
  max_visible_component_days: number;
  allow_subscriptions: boolean;
  allow_subscriptions_email: boolean;
  allow_subscriptions_rss: boolean;
  allow_subscriptions_slack: boolean;
  allow_subscriptions_sms: boolean;
  allow_subscriptions_webhook: boolean;
  visibility_level: StatusPageVisibilityLevel;
  timezone: string;
  time_format: string;
  used_timezone: string;
  show_status_tab: boolean;
  show_active_incidents: boolean;
  show_component_response_time: boolean;
  show_history_tab: boolean;
  uptime_calculation_type: UptimeCalculationType;
  allow_pdf_report: boolean;
  show_history_snake: boolean;
  show_summary_metrics: boolean;
  show_component_history: boolean;
  hide_empty_tabs_status: boolean;
  hide_empty_tabs_history: boolean;
  show_past_incidents: boolean;
  allow_search_indexing: boolean;
  allow_drill_down: boolean;
  components: Component[];
  metrics: Metric[];
  active_incidents: Incident[];
  upcoming_maintenance: Incident[];
  logo_url: string;
  favicon_url: string;
  google_analytics_code: string;
  contact_email: string;
  custom_css: string;
  custom_header_html: string;
  custom_footer_html: string;
  custom_css_inspire: string;
  custom_header_html_inspire: string;
  custom_footer_html_inspire: string;
  email_logo_url: string;
  email_from: string;
  email_reply_to: string;
  company_website_url: string;
  auth_username: string;
  auth_password: string;
  samlSettings: SAMLSettings;
  min_visible_date: string;
  max_visible_date: string;
  past_incidents?: Incident[];
  global_is_operational: boolean;
  has_components_under_maintenance_state: boolean;
  has_components_under_critical_state: boolean;
  theme: StatusPageTheme;
  custom_header_bg_color_hex: string;
  custom_header_text_color_hex: string;
  global_metrics: StatusPageGlobalMetrics;
  date_history?: Record<string, DateHistoryItem>;
  downtime_history?: StatusPageDowntimeItem[];
}

export interface DateHistoryItem {
  affected_components: AffectedComponent[];
  downtime_secs: number;
  outages: number;
  uptime_pct?: number;
}
