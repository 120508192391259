import React from 'react';
import {Incident as IncidentType} from '@/status_pages/types';
import Incident from './Incident';
import {IncidentsSortMenu} from '@/status_pages/display/components/IncidentsSortMenu';
import {useStatusPageContext} from '@/status_pages/display/context/hooks';

export interface IncidentsListProps {
  incidents: IncidentType[];
  title?: string;
}
export const IncidentsList = ({incidents, title}: IncidentsListProps) => {
  const {sortEventsBy, setSortEventsBy, componentStatusRank} = useStatusPageContext();
  return (
    <div className="incident-list">
      <div className="d-flex flex-row justify-content-between align-items-center mb-4">
        <h4 className="m-0 p-0">{title}</h4>
        <IncidentsSortMenu setSortBy={setSortEventsBy} sortBy={sortEventsBy} />
      </div>
      {incidents.map((incident, i) => (
        <Incident key={i} incident={incident} componentStatusRank={componentStatusRank} />
      ))}
      <div className="incident-end"></div>
    </div>
  );
};

export default IncidentsList;
